import View from './View'
const news = {
  path: '/news',
  component: View,
  children: [
    {
      path: '',
      name: 'news',
      component: () =>
        import( /* webpackChunkName:'News' */ '@/views/News'),
      meta: {
        title: "资讯",
        isShow: true
      }
    },
    {
      path: 'newsDetail',
      name: 'newDetail',
      component: () =>
        import( /* webpackChunkName:'NewsDetail' */ '@/views/News/NewsDetail'),
      meta: {
        title: "消息内容",
        isShow: true
      }
    }
  ]
}

export default news