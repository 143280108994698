
/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
	return /^(https?:|mailto:|tel:)/.test(path)
}

export function btoa(str, i) {
	if (i) {
		return window.btoa(encodeURI(JSON.stringify(str)))
	} else {
		return JSON.parse(decodeURI(window.atob(str)))
	}
}

/**
 * @param 
 * @returns {string}}
 */
export function twoDaysLater(time) {// 时间戳转换日期格式方法
	let nowDay = new Date(time).getDay()

	if (nowDay === 4 || nowDay === 5) {
		time += 172800000
	} else if (nowDay === 6) {
		time += 86400000
	}

	let date = new Date(time + 172800000);
	let m = date.getMonth() + 1; // 月
	m = m < 10 ? "0" + m : m;
	let d = date.getDate(); // 日
	d = d < 10 ? "0" + d : d;

	let day = date.getDay();
	let weeks = new Array(
		"周日",
		"周一",
		"周二",
		"周三",
		"周四",
		"周五",
		"周六"
	);
	return `${m}月${d}日（${weeks[day]}）`
}

/**
 * @param {number}} value
 * @returns {string}}
 */
export function formatDate(value) {// 时间戳转换日期格式方法
	if (value == null) {
		return '-';
	} else {
		let date = new Date(value);
		let y = date.getFullYear();// 年
		let MM = date.getMonth() + 1;// 月
		MM = MM < 10 ? ('0' + MM) : MM;
		let d = date.getDate();// 日
		d = d < 10 ? ('0' + d) : d;
		let h = date.getHours();// 时
		h = h < 10 ? ('0' + h) : h;
		let m = date.getMinutes();// 分
		m = m < 10 ? ('0' + m) : m;
		let s = date.getSeconds();// 秒
		s = s < 10 ? ('0' + s) : s;
		return y + '-' + MM + '-' + d + ' ' + h + ':' + m + ':' + s;
	}
}

/**
 * @param {number}} value
 * @returns {string}}
 */
export function formatDay(value) {// 时间戳转换日期格式方法
	if (value == null) {
		return '';
	} else {
		let date = new Date(value);
		let y = date.getFullYear();// 年
		let MM = date.getMonth() + 1;// 月
		MM = MM < 10 ? ('0' + MM) : MM;
		let d = date.getDate();// 日
		d = d < 10 ? ('0' + d) : d;
		return y + '年' + MM + '月' + d + '日'
	}
}


/**
 * @param {number}} value
 * @returns {string}}
 */
export function formatDay2(value) {// 时间戳转换日期格式方法
	if (value == null) {
		return '';
	} else {
		let date = new Date(value);
		let y = date.getFullYear();// 年
		let MM = date.getMonth() + 1;// 月
		MM = MM < 10 ? ('0' + MM) : MM;
		let d = date.getDate();// 日
		d = d < 10 ? ('0' + d) : d;
		return y + '-' + MM + '-' + d
	}
}

/**
 * @param {number}} value
 * @returns {string}}
 */
export function formatDay3(value) {// 时间戳转换日期格式方法
	if (value == null) {
		return '';
	} else {
		let date = new Date(value);
		let y = date.getFullYear();// 年
		let MM = date.getMonth() + 1;// 月
		MM = MM < 10 ? ('0' + MM) : MM;
		let d = date.getDate();// 日
		d = d < 10 ? ('0' + d) : d;
		return y + '.' + MM + '.' + d
	}
}

/**
 * @param {number} value
 * @returns {string}
 */
export function countDown(value) {
	if (value == null) {
		return '';
	} else {
		// console.log(value );
		let EndTime = new Date(value); //截止时间 
		let NowTime = new Date();

		// console.log(NowTime.getTime() - EndTime.getTime());

		let t = 1800000 - (NowTime.getTime() - EndTime.getTime());
		let d = Math.floor(t / 1000 / 60 / 60 / 24);
		let h = Math.floor(t / 1000 / 60 / 60 % 24);
		let m = Math.floor(t / 1000 / 60 % 60);
		let s = Math.floor(t / 1000 % 60);
		h = h < 10 ? ('0' + h) : h;
		m = m < 10 ? ('0' + m) : m;
		s = s < 10 ? ('0' + s) : s;

		return `${d}天${h}小时${m}分${s}秒`;

	}
}


/**
 * @param {object} value
 * @param {string} field
 * @returns {number}
 */
export function preferential(value, field) {
	let now = new Date();
	let m = now.getTime();

	if (!value.retailPrice || !value.retailPrice) return { price: null, state: 0 }

	if (value.preferentialStartTime === null) {
		if (field === 'retailPrice') {
			return { price: value.retailPrice.toFixed(2), state: 0 };
		} else if (field === 'tradePrice') {
			return { price: value.tradePrice.toFixed(2), state: 0 };
		} else {
			return 0
		}
	}

	// console.log(new Date(value.preferentialStartTime).toLocaleString());
	// console.log(new Date(m).toLocaleString());
	// console.log(new Date(value.preferentialEndTime).toLocaleString());

	if (value.preferentialStartTime < m && m < value.preferentialEndTime) {
		//优惠中
		if (field === 'retailPrice') {
			return { price: value.preferentialRetailPrice.toFixed(2), state: 1, preferentialPrice: Math.round((value.retailPrice - value.preferentialRetailPrice) * 100) / 100 };
		} else if (field === 'tradePrice') {
			return { price: value.preferentialTradePrice.toFixed(2), state: 1, preferentialPrice: Math.round((value.tradePrice - value.preferentialTradePrice) * 100) / 100 };
		} else {
			return 1
		}
	} else {
		//无优惠
		if (field === 'retailPrice') {
			return { price: value.retailPrice.toFixed(2), state: 0 };
		} else if (field === 'tradePrice') {
			return { price: value.tradePrice.toFixed(2), state: 0 };
		} else {
			return 0
		}
	}
}



/**
 * @param {number} value
 * @returns {string}
 */
export function formatMsgTime(timespan) {
	var nowTime = new Date()
	var nowYear = nowTime.getFullYear()
	var nowMonth = nowTime.getMonth() + 1

	var dateTime = new Date(timespan) // 将传进来的字符串或者毫秒转为标准时间
	var year = dateTime.getFullYear()
	var month = dateTime.getMonth() + 1
	var day = dateTime.getDate()
	var hour = dateTime.getHours()
	var minute = dateTime.getMinutes()
	// var second = dateTime.getSeconds()
	var millisecond = dateTime.getTime() // 将当前编辑的时间转换为毫秒
	var now = new Date() // 获取本机当前的时间
	var nowNew = now.getTime() // 将本机的时间转换为毫秒
	var milliseconds = 0
	var timeSpanStr
	milliseconds = nowNew - millisecond
	if (milliseconds <= 1000 * 60 * 1) { // 小于一分钟展示为刚刚
		timeSpanStr = '刚刚'
	} else if (1000 * 60 * 1 < milliseconds && milliseconds <= 1000 * 60 * 60) { // 大于一分钟小于一小时展示为分钟
		timeSpanStr = Math.round((milliseconds / (1000 * 60))) + '分钟前'
	} else if (1000 * 60 * 60 * 1 < milliseconds && milliseconds <= 1000 * 60 * 60 * 24) { // 大于一小时小于一天展示为小时
		timeSpanStr = Math.round(milliseconds / (1000 * 60 * 60)) + '小时前'
	} else if (1000 * 60 * 60 * 24 < milliseconds && milliseconds <= 1000 * 60 * 60 * 24 * 15) { // 大于一天小于十五天展示位天
		timeSpanStr = Math.round(milliseconds / (1000 * 60 * 60 * 24)) + '天前'
	} else if (milliseconds > 1000 * 60 * 60 * 24 * 15 && year === now.getFullYear()) {
		timeSpanStr = changeNumToHan(nowMonth - month) + '个月前'
	} else {
		timeSpanStr = changeNumToHan(nowYear - year) + '年前'
	}
	return timeSpanStr
}

function changeNumToHan(num) {
	var arr1 = ['零', '一', '两', '三', '四', '五', '六', '七', '八', '九']
	var arr2 = ['', '十', '百', '千', '万', '十', '百', '千', '亿', '十', '百', '千', '万', '十', '百', '千', '亿']
	if (!num || isNaN(num)) return '零'
	var english = num.toString().split('')
	var result = ''
	for (var i = 0; i < english.length; i++) {
		var des_i = english.length - 1 - i// 倒序排列设值
		result = arr2[i] + result
		var arr1_index = english[des_i]
		result = arr1[arr1_index] + result
	}
	result = result.replace(/零(千|百|十)/g, '零').replace(/十零/g, '十') // 将【零千、零百】换成【零】 【十零】换成【十】
	result = result.replace(/零+/g, '零') // 合并中间多个零为一个零
	result = result.replace(/零亿/g, '亿').replace(/零万/g, '万') // 将【零亿】换成【亿】【零万】换成【万】
	result = result.replace(/亿万/g, '亿') // 将【亿万】换成【亿】
	result = result.replace(/零+$/, '') // 移除末尾的零
	// 将【一十】换成【十】
	result = result.replace(/^一十/g, '十')
	return result
}


/**
 * 图片url链接转base64
 * @param {String} url 图片链接
 * @returns 转base64后的值或者报错信息
 */
export function imgUrlToBase64(url) {
	let p = new Promise((res, rej) => {
		var img = document.createElement('img');
		img.src = `${url}?v=${Math.random()}`;  //此处自己替换本地图片的地址
		img.crossOrigin = 'anonymous'

		img.onload = async function () {
			var canvas = document.createElement("canvas");
			let i = 200
			canvas.width = i
			canvas.height = i
			var ctx = canvas.getContext("2d");
			ctx.drawImage(img, 0, 0, i, i);
			var dataURL = canvas.toDataURL("image/png");
			// var img1 = document.createElement('img');
			// img1.src = data;
			res(dataURL)
		}
	})
	return p

	// return dataURL.replace("data:image/png;base64,", "");
};

/**
 * 获取可用优惠券
 * @param {array} couponList - 优惠券列表
 * @param {number} orderAmount - 订单总金额
 * @param {number} userIntegral - 用户积分
 * @return {object|null} - 返回可用的优惠券对象，如果无可用优惠券，则返回 null
 */
export function getAvailableCoupon(couponList, orderAmount, userIntegral) {
	let availableCoupon = null;
	let nextCoupon = null;

	for (let coupon of couponList) {
		// 判断积分是否足够
		// if (userIntegral < coupon.integral) {
		// 	break;
		// }

		// 判断订单金额是否达到使用门槛

		if (orderAmount < coupon.amount) {
			continue;
		}
		availableCoupon = coupon;
	}

	if (availableCoupon === null) availableCoupon = {
		"integral": 0,
		"amount": 0,
	}
	// 如果当前没有可用的优惠券，直接返回下一张优惠券
	let index = couponList.indexOf(availableCoupon);

	nextCoupon = couponList[index + 1]
	// if (!availableCoupon) {
	// 	nextCoupon = couponList
	// 	// .find((coupon) => userIntegral >= coupon.integral);
	// }
	// // 如果有可用的优惠券，查找下一张优惠券
	// else {
	// 	let index = couponList.indexOf(availableCoupon);
	// 	nextCoupon = couponList.slice(index + 1)
	// 	// .find((coupon) => userIntegral >= coupon.integral);
	// }
	return { availableCoupon, nextCoupon };
}


// localStorage存储数据时设置过期时间
export function localStorageSetItem(key, value, minutesTillExpire) {
	const now = new Date();
	const expirationDate = new Date(now.getTime() + minutesTillExpire * 60000);
	const item = { value, expirationDate };
	localStorage.setItem(key, JSON.stringify(item));
}

// localStorage读取数据时判断过期时间
export function localStorageGetItem(key) {
	const itemStr = localStorage.getItem(key);
	if (!itemStr) {
		return null;
	}
	try {
		const item = JSON.parse(itemStr);
		const expirationDate = new Date(item.expirationDate);
		if (expirationDate < new Date()) {
			localStorage.removeItem(key);
			return null;
		}
		return item.value;
	} catch (e) {
		return null;
	}
}

export function getStrByteLength(str) {
	if (!str) {
		return 0;
	}
	return encodeURIComponent(str).replace(/%../g, "x").length;
}
