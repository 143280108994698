import View from './View'
const activity = {
  path: '/activity',
  component: View,
  children: [
    {
      path: '',
      name: 'activity',
      component: () =>
        import( /* webpackChunkName:'Activity' */ '@/views/Activity'),
      meta: {
        title: "主题专区",
        isShow: true,
        noFixed: true,
        transparent: true,
      }
    },
    {
      path: 'activityDetail',
      name: 'activity-detail',
      component: () =>
        import( /* webpackChunkName:'ActivityDetail' */ '@/views/Activity/ActivityDetail'),
      meta: {
        keepAlive: true

      }
    }
  ]
}

export default activity