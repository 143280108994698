import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import routes from './routes/index'
import { getToken, getOpenId } from "@/utils/auth";
Vue.use(VueRouter)


const router = new VueRouter({
	base: process.env.BASE_URL,
	routes
})


router.beforeEach(async (to, from, next) => {
	if (to.meta.skip) {
		next()
		return
	}
	if (location.hash.split("PDA=")[1]) {
		if (getToken() && !store.state.user.isLogin) await store.dispatch('user/getUser')
		next()
		return
	}

	async function wx() {
		if (!getOpenId()) {
			if (window.location.search) {
				await store.dispatch('user/getOpenId')
			} else {

				//正品汇
				// window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx8845a1377cb25771&redirect_uri=${encodeURIComponent(
				// 	`https://sgmall.origmall.cn/#${to.fullPath}`
				// )}&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect`;

				//测试广银接口
				window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx9680283394cfbcba&redirect_uri=${encodeURIComponent(
					`https://sgmall.origmall.cn/#${to.fullPath}`
				)}&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect`;
				// test.origmall.cn

				// window.location.href = `http://192.168.1.140:8080/?code=051MMq100JPKZO1Pqw300hsiko1MMq1h&state=STATE#${to.fullPath}`

			}
		}
	}

	if (typeof WeixinJSBridge == "undefined") {
		if (document.addEventListener) {
			document.addEventListener(
				"WeixinJSBridgeReady",
				wx,
				false
			);
		} else if (document.attachEvent) {
			document.attachEvent("WeixinJSBridgeReady", wx);
			document.attachEvent("onWeixinJSBridgeReady", wx);
		}
	} else {
		wx();
	}


	if (getToken()) {
		if (to.name === 'login' || to.name === 'register') {
			next('/')
		}
	} else {

		if (to.name === 'my-order' || to.name === 'address' || to.name === 'user-data' || to.name === 'integral') {
			// console.log(router);
			next('/Login')
		}
	}
	if (getToken() && !store.state.user.isLogin) {
		// console.log("刷新页面 获取用户信息");
		await store.dispatch('user/getUser')
		await store.dispatch('user/getTzInfo')
		await store.dispatch('user/queryPromoterById')

		// store.state.user.promoter && await store.dispatch('user/queryElectronicAcct')

		// if (!sessionStorage.getItem('openid')) {
		// 	window.location.search && store.dispatch('user/getOpenId')
		// }
	}

	if (getToken() && store.state.user.isLogin && store.state.user.tzInfo === '') {
		// console.log("没店长信息 重新获取");
		await store.dispatch('user/findTzIdByuserId')
		await store.dispatch('user/getTzInfo')
	}
	if (store.state.user.isLogin) {
		if (sessionStorage.getItem("userUuid") && store.state.user.userInfo.userEntity.referrerUserId && sessionStorage.getItem("userUuid") != store.state.user.tzInfo.id) {
			// console.log("扫的店长码 和当前推荐人id不一致 优先扫描的店长码 清除原店长信息");
			store.commit("user/removeTzInfo")
			await store.dispatch('user/getTzInfo')
		}
	}
	next()
})

export default router

