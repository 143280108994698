import request from '@/utils/request'  //20003
import request2 from '@/utils/userRequest' //20050

//登录
export async function login(data) {
    return await request2({
        url: '/login',
        method: 'post',
        data
    })
}

//注册 
export async function register(data) {
    return await request2({
        url: '/consumer-user-server/User/registerUser',
        method: 'post',
        data
    })
}

//发送验证码
export async function sendCAPTCHA(data) {
    return await request({
        url: '/external-server/Message/sendCAPTCHA',
        method: 'post',
        data
    })
}

//验证验证码
export async function verificationCAPTCHA(data) {
    return await request({
        url: '/external-server/Message/verificationCAPTCHA',
        method: 'post',
        data
    })
}

//更新用户
export async function updateUser(data) {
    return await request2({
        url: '/consumer-user-server/User/updateUser',
        method: 'post',
        data
    })
}
//根据id获取用户信息
export async function getUserData(data) {
    return await request2({
        url: '/consumer-user-server/User/findUserById',
        method: 'post',
        data
    })
}

//团长id查询银行会员店详情
export async function queryBankStoreDetailByUserId(data) {
    return await request2({
        url: '/consumer-user-server/User/queryBankStoreDetailByUserId',
        method: 'post',
        data
    })
}
//银行会员店注册会员
export async function insertBankConsumerUser(data) {
    return await request2({
        url: '/consumer-user-server/User/insertBankConsumerUser',
        method: 'post',
        data
    })
}