import axios from 'axios'

// create an axios instance
const service = axios.create({

  baseURL: 'https://mingle.origmall.cn', // url = base url + request url 
  // baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url 
  // 20003
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 30000 // request timeout
})
service.interceptors.request.use(config => {
  // config.headers['Region'] = "SG"
  // config.headers['Region'] = "CS"
  config.headers['Region'] = "QG"
  return config
},
  err => {
    console.log(err)
    return Promise.reject(err)
  });

export default service
