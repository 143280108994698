import View from './View'
const myOrder = {
  path: '/myOrder',
  component: View,
  children: [
    {
      path: '',
      name: 'my-order',
      component: () =>
        import( /* webpackChunkName:'MyOrder' */ '@/views/MyOrder'),
      meta: {
        // keepAlive: true
      }
    },
    {
      path: 'orderDetails',
      name: 'order-details',
      component: () =>
        import( /* webpackChunkName:'OrderDetails' */ '@/views/MyOrder/OrderDetails'),
      meta: {
        title: "订单详情",
        isShow: true
      }
    },
    {
      path: 'logisticsInformation',
      name: 'logistics-information',
      meta: {
        title: "物流信息",
        isShow: true,
      },
      component: () =>
        import( /* webpackChunkName:'LogisticsInformation' */ '@/views/MyOrder/LogisticsInformation')
    }
  ]
}

export default myOrder