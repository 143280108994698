<template>
  <transition name="van-fade">
    <div class="function_button" v-show="show">
      <!-- :style="[{ bottom: btnInfo.length > 3 ? '2rem' : '1.6rem' }]" -->
      <div
        v-for="(item, i) in btnInfo"
        @click="item.function"
        :key="i"
        :style="[
          {
            transform: showBtn
              ? `translate(${translation[i].x},${translation[i].y})`
              : '',
          },
        ]"
        :class="showBtn ? 'open' : ''"
      >
        <svg-icon
          :icon-class="item.icon"
          :style="[
            {
              transform:
                showBtn && item.icon === 'feature' ? 'rotate(-45deg)' : '',
            },
          ]"
        />
        <span v-if="item.text">{{ item.text }}</span>
      </div>
    </div>
  </transition>
</template>

<script>
import sharePopup from "@/components/SharePopup/index.js";
import { mapState } from "vuex";
import { Dialog } from "vant";
export default {
  name: "function-button",
  props: {
    shareBtn: {
      type: Boolean,
      default: false,
    },
    homePageBtn: {
      type: Boolean,
      default: false,
    },
    backToTopBtn: {
      type: Boolean,
      default: true,
    },
    domNode: {
      default: undefined,
    },
    isHidden: {
      type: Boolean,
      default: false,
    },
    shareInfo: {
      type: Object,
    },
  },
  computed: {
    ...mapState("user", ["isLogin", "promoter"]),
  },
  watch: {
    shareBtn: {
      handler(val) {
        if (val) this.showBtn = true;

        if (!val) {
          let i = this.btnInfo.findIndex((e) => e.icon === "share_icon");
          this.btnInfo.splice(i, 1);
        }
      },
      immediate: true,
    },
    homePageBtn: {
      handler(val) {
        if (!val) {
          let i = this.btnInfo.findIndex(
            (e) => e.icon === "tabble_home_normal"
          );
          this.btnInfo.splice(i, 1);
        }
      },
      immediate: true,
    },
    backToTopBtn: {
      handler(val) {
        if (!val) {
          let i = this.btnInfo.findIndex((e) => e.icon === "button_top_icon");
          this.btnInfo.splice(i, 1);
        }
      },
      immediate: true,
    },
    btnInfo: {
      handler(val) {
        if (val.length === 2) {
          val.splice(0, 1);
          this.hidden = true;
        }
      },
      immediate: true,
    },
  },
  data() {
    return {
      show: false,
      showBtn: false,
      hidden: this.isHidden,
      btnInfo: [
        {
          icon: "feature",
          function: this.switch,
        },

        {
          text: "首页",
          icon: "tabble_home_normal",
          function: this.toHomePage,
        },
        {
          text: "顶部",
          icon: "button_top_icon",
          function: this.backToTop,
        },
        {
          text: "分享",
          icon: "share_icon",
          function: this.share,
        },
      ],
      translation: [
        { x: "", y: "0" },
        { x: "0", y: "-58px" },
        { x: "0", y: "-118px" },
        { x: "0", y: "-178px" },
      ],
    };
  },
  created() {},
  activated() {
    if (this.hidden) {
      window.addEventListener("scroll", this.handleScrollx, true);
    } else {
      this.show = true;
    }
  },
  mounted() {
    if (this.hidden) {
      window.addEventListener("scroll", this.handleScrollx, true);
    } else {
      this.show = true;
    }
  },
  methods: {
    switch() {
      this.showBtn = !this.showBtn;
    },
    share() {
      // if (this.promoter) {
        sharePopup(this.shareInfo);
      // } else {
      //   Dialog.confirm({
      //     title: "提示",
      //     message:
      //       "是否加入正品汇分佣推广?<br/>加入后享受专属权益哦~<br/><a href='#/promotion/Instructions'>了解更多></a>",
      //     theme: "round-button",
      //     confirmButtonText: "去认证",
      //     confirmButtonColor: "#FFC426",
      //     cancelButtonText: "普通分享",
      //     cancelButtonColor: "#FFC426",
      //     // closeOnClickOverlay: true,
      //     className: "fyTips",
      //     overlayClass: "fyOverlay",
      //   })
      //     .then(() => {
      //       if (this.isLogin) {
      //         this.$router.push("/promotion/promotionRegistration");
      //       } else {
      //         this.$router.push(
      //           `/login?redirect_uri=/promotion/promotionRegistration`
      //         );
      //       }
      //       // on close
      //     })
      //     .catch(() => {
      //       sharePopup(this.shareInfo);
      //     });
      // }

      // this.showBtn = false;
    },
    toHomePage() {
      this.$router.push({
        path: "/",
        query: {
          id: this.$route.query.id ? this.$route.query.id : undefined,
        },
      });
    },
    backToTop() {
      let top =
        window.pageXOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        this.domNode.scrollTop;
      // 实现滚动效果
      // let top = document.getElementById("goods").scrollTop;
      // 实现滚动效果
      const timeTop = setInterval(() => {
        if (window.pageXOffset) {
          window.pageXOffset = top -= 60;
        } else if (document.documentElement.scrollTop) {
          document.documentElement.scrollTop = top -= 60;
        } else if (document.body.scrollTop) {
          document.body.scrollTop = top -= 60;
        } else if (this.domNode) {
          this.domNode.scrollTop = top -= 60;
        }

        if (top <= 0) {
          clearInterval(timeTop); //清除定时器
        }
      }, 10);
      this.showBtn = false;
    },
    handleScrollx() {
      let offset = 0;

      if (this.domNode) {
        offset = this.domNode.scrollTop;
      } else {
        offset =
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop;
      }

      let outerHeight =
        window.outerHeight ||
        document.documentElement.outerHeight ||
        document.body.outerHeight;

      if (offset > outerHeight) {
        this.show = true;
      } else {
        this.show = false;
      }
    },
  },
  deactivated() {
    this.show = false;
    window.removeEventListener("scroll", this.handleScrollx, true);
  },
  beforeDestroy() {
    this.show = false;
    window.removeEventListener("scroll", this.handleScrollx, true);
  },
};
</script>

<style lang="scss" scoped>
.function_button {
  position: fixed;
  right: 20px;
  bottom: 160px;
  z-index: 99;
  div {
    position: absolute;
    right: 0;
    top: 0;
    background: rgba($color: #fff, $alpha: 0.8);
    color: #666;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 46px;
    height: 46px;
    transition: 0.2s all;
    .svg-icon {
      transition: 0.2s all;
    }
  }
  div:nth-child(1) {
    background: #fff;
    box-shadow: 0px 2px 6px 1px rgba($color: #333333, $alpha: 0.16);
    z-index: 100;
  }
  .open {
    background: rgba($color: #fff, $alpha: 0.8) !important;
    box-shadow: 0px 2px 6px 1px rgba($color: #333333, $alpha: 0.16);
  }
}
</style>
<style lang="scss">
.fyOverlay {
  background: rgba($color: #333, $alpha: 0.48) !important;
}
.fyTips {
  max-width: 290px;
  padding-top: 105px;
  background: url("../../assets/images/pop-up_bg.png") no-repeat;
  background-color: #fff !important;
  border-radius: 20px !important;
  .van-dialog__header {
    padding: 0;
    line-height: 26px;
    font-weight: bold;
  }
  .van-dialog__content {
    .van-dialog__message {
      padding: 10px 0;
      line-height: 26px;
      a {
        color: #108bff;
      }
    }
  }

  .van-dialog__footer {
    padding-bottom: 24px !important;
    .van-button {
      height: 44px !important;
      border-radius: 22px;
      margin: 0 8px;
    }
    .van-button--warning {
      background: #fff !important;
      color: #ffc426 !important;
      border: 1px solid #ffc426;
    }
  }
}
</style>
