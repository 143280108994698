import request from '@/utils/userRequest' //20050

//获取openId
export async function openIdBycode(data) {
	return await request({
		url: '/pay-server/wxPay/getOpenIdByCode',
		method: 'post',
		data
	})
}
//形成微信订单
export async function insertWxOrder(data) {
	return await request({
		url: '/pay-server/wxPay/insertWxOrder',
		method: 'post',
		data
	})
}
//微信分享
export async function wxShare(data) {
	return await request({
		url: '/pay-server/wxPay/wxShare',
		method: 'post',
		data
	})
}

//广银微信获取微信openid
export async function bankGetOpenIdByCode(data) {
	return await request({
		url: '/consumer-shopping-server/Payment/getOpenIdByCode',
		method: 'post',
		data
	})
}
//广银微信支付
export async function WchtAppPcdrPyOrder(data) {
	return await request({
		url: '/consumer-shopping-server/Payment/WchtAppPcdrPyOrder',
		method: 'post',
		data
	})
}

//用户充值电子账簿支付
export async function balancePay(data) {
	return await request({
		url: '/consumer-shopping-server/Payment/balancePay',
		method: 'post',
		data
	})
}



