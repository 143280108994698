import Cookies from 'js-cookie'

//token_personal
const TokenKey = 'token_personal'  //APPMGRID这个token名字自己定义

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token, { expires: 31 })
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}


const ToKenKey2 = 'token_openid'

export function getOpenId() {
  return Cookies.get(ToKenKey2)
}

export function setOpenId(token) {
  return Cookies.set(ToKenKey2, token, { expires: 31 })
}

export function removeOpenId() {
  return Cookies.remove(ToKenKey2)
}