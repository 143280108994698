import Vue from 'vue'
import Vuex from 'vuex'
import user from './user'
import pay from './pay'
Vue.use(Vuex)

export default new Vuex.Store({
  strict: true,
  state: {
    App: false
  },
  mutations: {
    isApp(state, payload) {
      state.App = true
    },
  },
  actions: {

  },
  modules: {
    user, pay
  }
})

