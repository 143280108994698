import View from './View'
const payOrder = {
  path: '/confirmAnOrder',
  component: View,
  children: [
    {
      path: '',
      name: 'confirm-an-order',
      meta: {
        title: "确认订单",
        isShow: true
      },
      component: () =>
        import( /* webpackChunkName:'ConfirmAnOrder' */ '@/views/ConfirmAnOrder')
    },
    {
      path: 'payForTheOrder',
      name: 'pay-for-the-order',
      component: () =>
        import( /* webpackChunkName:'PayForTheOrder' */ '@/views/ConfirmAnOrder/PayForTheOrder'),
      meta: {
        title: "支付订单",
        isShow: true
      }
    },
    {
      path: 'completionOfPayment',
      name: 'completion-of-payment',
      component: () =>
        import( /* webpackChunkName:'' */ '@/views/ConfirmAnOrder/CompletionOfPayment')
    }
  ]
}


export default payOrder