import View from './View'
const login = {
  path: '/login',
  component: View,
  children: [
    {
      path: '',
      name: 'login',
      component: () =>
        import( /* webpackChunkName: "Login" */ '@/views/Login'),
      meta: {
        title: "登录",
        isShow: true
      },
    },
    {
      path: 'retrievePassword',
      name: 'retrieve-password',
      component: () =>
        import( /* webpackChunkName: "RetrievePassword" */ '@/views/Login/RetrievePassword'),
      meta: {
        title: "找回密码",
        isShow: true
      },
    }
  ]
}

export default login