import request from '@/utils/request'

//热销分类列表
export async function HotSaleActivityCategoryList(data) {
  return await request({
      url: '/business-server/HotSaleActivity/HotSaleActivityCategoryList',
      method: 'post',
      data
  })
}

//热销榜单列表
export async function HotSaleActivityList(data) {
  return await request({
      url: '/business-server/HotSaleActivity/HotSaleActivityList',
      method: 'post',
      data
  })
}
