
import request from '@/utils/request'  //20003


//单品详情
export async function getGoods(data) {
    return await request({
        url: '/business-server/Traceability/queryBarcodeByNumber',
        method: 'post',
        data
    })
}

//价格详情
export async function getGoodsPrice(data) {
    return await request({
        url: '/business-server/CommodityMgt/CommodityPriceDetails',
        method: 'post',
        data
    })
}

//商品详情
export async function getCommodityDetails(data) {
    return await request({
        url: '/business-server/CommodityMgt/CommodityDetails',
        method: 'post',
        data
    })
}

//查询新人尝鲜价
export async function queryCommTastingPrice(data) {
    return await request({
        url: '/business-server/CommodityMgt/queryCommTastingPrice',
        method: 'post',
        data
    })
}

//商品秒杀详情
export async function querySeckillCommodityByCommId(data) {
    return await request({
        url: '/business-server/HomeSecKill/querySeckillCommodityByCommId',
        method: 'post',
        data
    })
}

//商品当前预估佣金
export async function commodityCommission(data) {
    return await request({
        url: '/business-server/CommodityMgt/CommodityCommission',
        method: 'post',
        data
    })
}
