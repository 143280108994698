import request from '@/utils/userRequest'//20050
import request2 from '@/utils/request'  //20003


// import axios from 'axios'
// import Cookies from "js-cookie";

// const service = axios.create({
//     baseURL: 'http://192.168.1.44:20050/', // url = base url + request url 
//     // 20003
//     // withCredentials: true, // send cookies when cross-domain requests
//     timeout: 30000 // request timeout
// })
// service.interceptors.request.use(config => {

//     // config.headers['Region'] = "SG"
//     config.headers['Region'] = "QG"
//     const token = Cookies.get("token_personal");
//     token && (config.headers.common['Authorization'] = `Bearer ${token}`)
//     return config
// },
//     err => {
//         console.log(err)
//         return Promise.reject(err)
//     });

//形成订单
export async function TransactionOrder(data) {
	return await request({
		url: '/consumer-shopping-server/Order/TransactionOrder',
		method: 'post',
		data
	})
}

//秒杀订单判断库存
export async function secKillTransactionOrder(data) {
	return await request({
		url: '/consumer-shopping-server/Order/secKillTransactionOrder',
		method: 'post',
		data
	})
}

//订单详情
export async function DealDetails(data) {
	return await request({
		url: '/consumer-shopping-server/Order/DealDetails',
		method: 'post',
		data
	})
}

//总订单详情
export async function TotalDealDetails(data) {
	return await request({
		url: '/consumer-shopping-server/Order/TotalDealDetails',
		method: 'post',
		data
	})
}

//订单列表
export async function ClosedOrderList(data) {
	return await request({
		url: '/consumer-shopping-server/Order/ClosedOrderList',
		method: 'post',
		data
	})
}

//修改订单状态
export async function UpdateDealStatus(data) {
	return await request({
		url: '/consumer-shopping-server/Order/UpdateDealStatus',
		method: 'post',
		data
	})
}

//取消订单
export async function CancelOrder(data) {
	return await request({
		url: '/consumer-shopping-server/Order/CancelOrder',
		method: 'post',
		data
	})
}

//删除订单
export async function DeleteOrder(data) {
	return await request({
		url: '/consumer-shopping-server/Order/DeleteOrder',
		method: 'post',
		data
	})
}

//购物车列表
export async function getShoppingCartList(data) {
	return await request({
		url: '/consumer-shopping-server/Order/OrderCartList',
		method: 'post',
		data
	})
}

//购物车总数
export async function getShoppingCartSum(data) {
	return await request({
		url: '/consumer-shopping-server/Order/OrderCartTotal',
		method: 'post',
		data
	})
}

//添加购物车
export async function InsertCusOrderCart(data) {
	return await request({
		url: '/consumer-shopping-server/Order/InsertCusOrderCart',
		method: 'post',
		data
	})
}

//批量添加购物车
export async function BatchInsertCusOrderCart(data) {
	return await request({
		url: '/consumer-shopping-server/Order/BatchInsertCusOrderCart',
		method: 'post',
		data
	})
}

//删除购物车
export async function DeleteOrderCart(data) {
	return await request({
		url: '/consumer-shopping-server/Order/DeleteOrderCart',
		method: 'post',
		data
	})
}

//修改购物车数量
export async function UpdateOrderCart(data) {
	return await request({
		url: '/consumer-shopping-server/Order/UpdateOrderCart',
		method: 'post',
		data
	})
}

//运单详情
export async function WaybillDetails(data) {
	return await request({
		url: '/consumer-shopping-server/CusOrder/queryRoute',
		method: 'post',
		data
	})
}

//修改订单状态
export async function updateOrderDealByDealNumber(data) {
	return await request({
		url: '/consumer-shopping-server/Order/updateOrderDealByDealNumber',
		method: 'post',
		data
	})
}

//形成订单扣除用户积分
export async function OrderConsumeIntegral(data) {
	return await request({
		url: '/consumer-shopping-server/Order/OrderConsumeIntegral',
		method: 'post',
		data
	})
}

//平台积分规则
export async function IntegralRuleDetail(data) {
	return await request({
		url: '/consumer-shopping-server/IntegralRule/IntegralRuleDetail',
		method: 'post',
		data
	})
}

//积分商城订单
//形成积分商城订单
export async function TransactionIntegralOrder(data) {
	return await request({
		url: '/consumer-integral-server/IntegralOrder/TransactionIntegralOrder',
		method: 'post',
		data
	})
}

//积分商城订单列表
export async function IntegralOrderList(data) {
	return await request({
		url: '/consumer-integral-server/IntegralOrder/IntegralOrderList',
		method: 'post',
		data
	})
}

//积分商城订单详情
export async function IntegralDealDetails(data) {
	return await request({
		url: '/consumer-integral-server/IntegralOrder/IntegralDealDetails',
		method: 'post',
		data
	})
}

//修改积分订单状态
export async function UpdateIntegralDealStatus(data) {
	return await request({
		url: '/consumer-integral-server/IntegralOrder/UpdateIntegralDealStatus',
		method: 'post',
		data
	})
}
