import Vue from 'vue'
import Popup from './sharePopup.vue'

const PopupConstructor = Vue.extend(Popup)
let PopupInstance = null

function sharePopup(info, remove) {
  if (remove) {
    let share = document.getElementById("share_popup")
    share && document.body.removeChild(share)
    PopupInstance = null
    return
  }
  if (PopupInstance) {
    // 已存在实例，切换显示和隐藏状态
    PopupInstance.show = !PopupInstance.show
    return
  }
  // 实例化一个 .vue 组件
  PopupInstance = new PopupConstructor()
  // 将组件对象手动挂载到某一个元素上
  PopupInstance.$mount(document.createElement('div'))
  document.body.appendChild(PopupInstance.$el)

  // 设置显示状态和数据
  PopupInstance.show = true
  PopupInstance.info = info
}

export default sharePopup
