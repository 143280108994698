
const store = {
  path: '/store',
  name: 'store',
  component: () =>
    import( /* webpackChunkName:'Store' */ '@/views/Store'),
  redirect: 'store/homePage',
  children: [
    {
      path: 'homePage',
      name: 'store-home-page',
      component: () =>
        import( /* webpackChunkName:'HomePage' */ '@/views/Store/HomePage')
    },
    {
      path: 'storeCategory',
      name: 'store-category',
      component: () =>
        import( /* webpackChunkName:'StoreCategory' */ '@/views/Store/StoreCategory')
    }
  ]
}
export default store