<template>
  <div id="app">
    <van-nav-bar
      :title="$route.meta.title"
      @click-left="goBack"
      @click-right="$router.replace('/home/shoppingCart')"
      :fixed="!$route.meta.noFixed"
      v-show="$route.meta.isShow"
      :class="[
        $route.name === 'wallet' ? 'wallet_bar' : '',
        $route.meta.transparent ? 'transparent_bar' : '',
      ]"
      :style="[
        { '--color': $route.meta.color ? $route.meta.color : '#333' },
        { background: $route.meta.background ? $route.meta.background : '' },
      ]"
      z-index="999"
    >
      <template #left>
        <van-icon name="arrow-left" v-if="back" />
        <van-icon name="wap-home-o" v-else />
      </template>
      <template #right>
        <van-icon
          v-if="
            $route.name === 'early-adopters' ||
            $route.name === 'hot-list' ||
            $route.name === 'great-value-special' ||
            $route.name === 'new-product-express'
          "
          name="shopping-cart-o"
          color="#fff"
          size="20px"
          :badge="quantity > 99 ? '99+' : quantity"
        />
      </template>
    </van-nav-bar>
    <div
      class="countdown"
      :style="[
        { top: $route.name === 'goods-list' ? '1.25rem' : '' },
        { position: $route.name === 'new-product-express' ? 'absolute' : '' },
      ]"
      v-if="taskShow"
    >
      {{
        currentTask.time
          ? currentTask.time < 0
            ? "完成任务接口报错，刷新页面重试"
            : `浏览${currentTask.time}秒即可领取积分`
          : "已完成浏览任务，可领取积分"
      }}
    </div>
    <!-- <keep-alive include="home-page,goods-list">
      <router-view></router-view>
    </keep-alive> -->

    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" :key="key"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" :key="key"></router-view>
    <!-- <div
      class="back_to_top"
      @click="backToTop"
      :style="[
        { right: show ? '' : '-60px' },
        { bottom: isLogin ? '65px' : '' },
      ]"
      v-show="$route.meta.backTopShow"
    >
      <svg-icon icon-class="button_top_icon" />顶部
    </div> -->
  </div>
</template>
<script>
import { getOpenId, removeOpenId } from "@/utils/auth";
import { mapState } from "vuex";
export default {
  name: "app",
  data() {
    return {
      back: false,
      // show: false,
      currentTask: {
        time: 10,
        taskId: "",
      },
      taskShow: false,
    };
  },
  computed: {
    ...mapState("user", ["isLogin", "quantity", "taskList"]),
    // 路由修改时, 方法执行
    key(val) {
      // console.log(this.$route.meta);
      // console.log(this.$route.name);
      // console.log(window.history.length);
      this.$route.path && window.history.length > 2 && (this.back = true);
    },
  },
  watch: {
    $route(val) {
      clearInterval(this.t);
      this.taskShow = false;
      if (val.query.taskId) this.task();
      if (val.name === "sign-in") this.currentTask.taskId = "";
    },
  },

  created() {
    window.Android && this.$store.commit("isApp");
  },
  mounted() {
    if (getOpenId() == "undefined" || getOpenId() == "null") removeOpenId();
    // window.addEventListener("scroll", this.handleScrollx, true);
  },
  methods: {
    // handleScrollx() {
    //   if (window.pageYOffset > window.outerHeight) {
    //     this.show = true;
    //   } else {
    //     this.show = false;
    //   }
    // },

    goBack() {
      if (this.$route.name === "set") {
        this.$router.push("/home/mine");
        return;
      }
      if (this.$route.name === "video") {
        if (this.$route.query.taskId) {
          this.$router.back();
        } else {
          this.$router.replace("/");
        }
        return;
      }
      if (this.back) {
        this.$router.back();
      }
      // else if (this.$route.name === "my-order") {
      //   this.$router.push("/Home?index=3");
      // }
      else {
        this.$router.push("/");
      }
    },
    // backToTop() {
    //   let top =
    //     window.pageXOffset ||
    //     document.documentElement.scrollTop ||
    //     document.body.scrollTop;
    //   // 实现滚动效果

    // const timeTop = setInterval(() => {
    //   if (window.pageXOffset) {
    //     window.pageXOffset = top -= 60;
    //   } else if (document.documentElement.scrollTop) {
    //     document.documentElement.scrollTop = top -= 60;
    //   } else {
    //     document.body.scrollTop = top -= 60;
    //   }

    //     if (top <= 0) {
    //       clearInterval(timeTop); //清除定时器
    //     }
    //   }, 10);
    // },
    async task() {
      if (!this.taskList.length)
        await this.$store.dispatch("user/getTaskQueryList");
      this.taskList.forEach((e) => {
        let _task = e.list.find((task) => {
          return task.id === this.$route.query.taskId;
        });

        if (!_task) return;
        if (this.currentTask.taskId != _task.id) this.currentTask.time = 10;
        if (_task && _task.countdownTask && _task.validStatus) {
          this.taskShow = true;
          this.currentTask.taskId = _task.id;
          if (_task.times >= _task.upLimit) this.currentTask.time = 0;
          if (this.currentTask.time) {
            this.t = setInterval(() => {
              this.currentTask.time--;
              if (!this.currentTask.time) {
                clearInterval(this.t);
                this.$store
                  .dispatch("user/taskFinished", this.$route.query.taskId)
                  .catch((e) => {
                    this.currentTask.time = -1;
                    this.Toast.fail(e);
                  });
              }
            }, 1000);
          }
        }
      });
    },
  },
};
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
#app {
  font-size: 14px;
  height: 100%;
  background-color: #fff;
  position: relative;
}
</style>
<style lang="scss" scoped>
::v-deep .van-nav-bar {
  max-width: 500px;
  margin: 0 auto;
  right: 0;
  .van-nav-bar__left .van-icon {
    font-size: 18px;
    color: var(--color);
  }
  .van-nav-bar__title {
    font-size: 16px;
    color: var(--color);
    font-weight: bold;
  }
}
::v-deep .wallet_bar {
  background: #467dff !important;
  .van-nav-bar__left i {
    color: #fff !important;
  }
  .van-nav-bar__title {
    color: #fff !important;
  }
}
::v-deep .transparent_bar {
  background: transparent;
  position: absolute;
  left: 0;
  right: 0;
  .van-nav-bar__left i {
    color: #fff !important;
  }
  .van-nav-bar__title {
    color: #fff !important;
  }
}
.van-hairline--bottom::after {
  display: none;
}
.countdown {
  position: fixed;
  top: 46px;
  left: 0;
  right: 0;
  max-width: 500px;
  margin: 0 auto;
  text-align: center;
  line-height: 36px;
  color: #fff;
  background: #333333;
  opacity: 0.9;
  z-index: 998;
}

// .back_to_top {
//   position: fixed;
//   right: 20px;
//   bottom: 105px;
//   // background: rgba($color: #fff, $alpha: 0.5);
//   background: #fff;
//   color: #666666;
//   border-radius: 50%;
//   width: 50px;
//   height: 50px;
//   font-size: 12px;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   z-index: 9999;
//   transition: 0.3s all;
//   box-shadow: 0px 2px 6px 1px rgba($color: #333333, $alpha: 0.16);
// }
</style>
