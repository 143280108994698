import { getUser, findUserById, CusBankFilePathList, checkInRuleDetails, checkInRecord, insertCheckInRecord, taskFinished, taskQueryList, findpurchaseIdByuserId, queryPromoterById, selectSettleAcctList, queryElectronicAcct } from "@/api/User"
import { getShoppingCartSum, IntegralRuleDetail } from "@/api/Order"
import { openIdBycode, bankGetOpenIdByCode } from "@/api/Pay"
import { setOpenId, getOpenId, removeToken } from "@/utils/auth"
// import signInTips from "@/components/SignInTips";

import _this from '@/main.js'

export default {
	namespaced: true,
	state: {
		isLogin: false,
		userInfo: null,
		userType: '',
		quantity: 0,
		tzInfo: '',
		bank: [],
		zphIntegral: 0,
		integralSum: 0,
		integralRule: null,
		maxIntegralBank: { integral: 0 },
		change: false,
		//签到
		// isSignIn: null,
		// signInDaysList: [],
		// signInDaysNum: 0,
		// bonusPoints: []
		//任务
		taskList: [],
		//推广员
		promoter: null,
		bankCard: [],
		electronicAcct: []
	},
	mutations: {
		setUserInfo(state, payload) {
			state.isLogin = true
			state.userInfo = payload
			state.userType = payload.userEntity.userType
		},
		setPromoterInfo(state, payload) {
			state.promoter = payload
		},
		setBankCard(state, payload) {
			state.bankCard = payload
			// console.log(state.bankCard);
		},
		setElectronicAcctInfo(state, payload) {
			state.electronicAcct = payload
		},
		setQuantity(state, payload) {
			payload === null ? state.quantity = 0 : state.quantity = payload.sum
		},
		LoginOut(state, payload) {
			state.isLogin = false
			state.userInfo = null
			state.userType = ''
			state.quantity = 0
			state.tzInfo = null
			state.bank = []
			state.allIntegral = 0
			state.zphIntegral = 0
			state.maxIntegralBank = { integral: 0 }
			state.change = true
			state.integralSum = 0
			state.integralRule = null
			state.taskList = []
			state.promoter = null
			state.bankCard = []
			state.electronicAcct = []
			// state.isSignIn = null
			// state.signInDaysList = []
			// state.signInDaysNum = 0
			// state.bonusPoints = []
		},
		setTzInfo(state, payload) {
			state.tzInfo = payload
		},
		setBank(state, payload) {
			state.bank = payload
			// console.log(payload);
			let b = payload.find(item => {
				return item.bankId === '0'
			})
			if (b) state.zphIntegral = b.integral
		},
		setIntegralSum(state, payload) {
			state.integralSum = payload
		},
		setMaxIntegralBank(state, payload) {
			state.maxIntegralBank = payload
		},
		removeTzInfo(state) {
			state.tzInfo = null
		},
		ShoppingCartSumChange(state, payload) {
			state.change = payload
		},
		setIntegralRule(state, payload) {
			state.integralRule = payload
		},
		setTaskList(state, payload) {
			state.taskList = payload
		},
		// setSignInDaysList(state, payload) {
		// 	state.signInDaysList = payload
		// },
		// setIsSignIn(state, payload) {
		// 	state.isSignIn = payload
		// },
		// setSignInDaysNum(state, payload) {
		// 	state.signInDaysNum = payload
		// },
		// setBonusPoints(state, payload) {
		// 	state.bonusPoints = payload
		// }
	},
	actions: {
		async getUser(context) {
			await getUser({ object: {} }).then(res => {
				if (res.data.code !== 'SUCCESS') throw Error(res.data.msg)
				context.commit('setUserInfo', res.data.data.userInfo);
				if (process.env.NODE_ENV === "production" && (res.data.data.userInfo.userEntity.telNum === '15622574300' || res.data.data.userInfo.userEntity.telNum === '15622574302')) eruda.init()

				context.dispatch("getQuantity")
				context.dispatch("getCusBankFilePathList")
			}).catch(e => {
				console.log(e.request);
				if (location.hash.split('id=')[1]) {
					sessionStorage.setItem("userUuid", location.hash.split('id=')[1].split('&')[0])
				}
				removeToken()
				_this.$toast.fail('请求超时，请重新登录')
				_this.$router.push('/login')
				// if (e.request.status === 0) {

				// }
				return
			})
		},
		//查询是否为分佣用户
		async queryPromoterById(context) {
			await queryPromoterById({
				object: {
					id: context.state.userInfo.id
				}
			}).then(res => {
				if (res.data.code !== "SUCCESS") throw Error(res.data.msg);
				context.commit("setPromoterInfo", res.data.data)
			}).catch(e => {
				_this.$toast.fail(e.message);
				console.log(e);
			})
		},
		//资金账户信息
		async queryElectronicAcct(context) {
			if (!context.state.promoter) return
			await queryElectronicAcct({
				object: {
					balanceAcctId: "",
					serviceType: "1",
					userId: context.state.promoter.userId
				}
			}).then(async res => {

				if (res.data.code !== "SUCCESS") throw Error(res.data.msg);
				await context.dispatch('selectSettleAcctList')
				context.commit('setElectronicAcctInfo', res.data.data)
			}).catch(e => {
				_this.$toast.fail(e.message);
				console.log(e);
			})
		},
		//获取银行卡信息
		async selectSettleAcctList(context) {
			await selectSettleAcctList({
				object: {
					bankAcctNo: "",
					serviceType: "1",
					settleAcctId: "",
					userId: context.state.userInfo.id
				}
			}).then(res => {
				if (res.data.code !== "SUCCESS") throw Error(res.data.msg);
				res.data.data.map(e => {

					_this.$getBankcardinfo.getBankBin(parseInt(e.bankAcctNo), (err, data) => {
						if (!err) {
							e.bankCode2 = data.bankCode
							e.cardTypeName = data.cardTypeName
						} else {
							e.bankCode2 = ''
							e.cardTypeName = ''
						}
					})

					return e
				})
				console.log(res.data.data);
				context.commit("setBankCard", res.data.data)
			}).catch(e => {
				console.log(e);
			})
		},
		async updatedUserInfo(context) {
			const data = await getUser({ object: {} });
			context.commit('setUserInfo', data.data.data.userInfo);
		},
		async getQuantity(context) {
			const data = await getShoppingCartSum({
				object: {
					userId: context.state.userInfo.id
				}
			})
			context.commit('setQuantity', data.data.data)
			context.commit('ShoppingCartSumChange', true)
		},
		// async getOpenId() {
		// 	const data = await openIdBycode({
		// 		object: window.location.search.split("=")[1].split("&")[0],
		// 	});
		// 	data.data.data && sessionStorage.setItem("openid", data.data.data)
		// },
		async getTzInfo(context) {
			if (location.hash.split('id=')[1]) {
				sessionStorage.setItem("userUuid", location.hash.split('id=')[1].split('&')[0])
			}
			if (context.state.isLogin) {
				if (sessionStorage.getItem("userUuid")) {
					console.log("扫店长码");
					const data = await findUserById({
						object: {
							id: sessionStorage.getItem("userUuid"),
						},
					});
					if (data.data.code === 'USER_NOT_FOUND') {
						sessionStorage.removeItem('userUuid')
						_this.$toast.fail('无此店长码')
						window.location.href = window.location.origin
						// return
					}
					context.commit('setTzInfo', data.data.data.userInfo);
				} else if (context.state.userInfo.userEntity.referrerUserId && context.state.tzInfo !== '') {
					console.log("无店长码 获取用户绑定店长");
					const data = await findUserById({
						object: {
							id: context.state.userInfo.userEntity.referrerUserId
						},
					});
					if (data.data.data)
						sessionStorage.setItem("userUuid", data.data.data.id)

					context.commit('setTzInfo', data.data.data.userInfo);
				}
			}

		},
		async findTzIdByuserId(context) {
			await findpurchaseIdByuserId({
				"object": {
					"id": context.state.userInfo.id
				}
			}).then(async (res) => {
				context.commit('setTzInfo', res.data.data)
				if (res.data.code !== 'SUCCESS') {
					_this.$toast.fail('获取默认自提点失败')
					return
				}
				if (!res.data.data) return
				sessionStorage.setItem("userUuid", res.data.data.id)
			})
		},
		async getOpenId(context) {
			//线上
			// await openIdBycode({
			// 	object: window.location.search.split("=")[1].split("&")[0],
			// }).then((res) => {
			// 	console.log(res);
			// 	setOpenId(res.data.data)
			// 	// window.location.search = ''
			// })


			//测试广银接口
			await bankGetOpenIdByCode({
				object: window.location.search.split("=")[1].split("&")[0],
			}).then((res) => {
				console.log(res);
				setOpenId(res.data.data)
				// window.location.search = ''
			})


		},
		//获取积分列表
		async getCusBankFilePathList(context) {
			const data = await CusBankFilePathList({
				"object": {
					"id": context.state.userInfo.id
				},
				"pageNum": 0,
				"pageSize": 0
			});
			if (!data.data.data) return
			data.data.data.length && context.commit('setBank', data.data.data)
			let sum = 0
			let maxIntegralBank = { integral: 0 }
			data.data.data.forEach(bank => {
				if (bank.bankId !== "0") {
					sum += bank.integral
					if (maxIntegralBank.integral < bank.integral) maxIntegralBank = bank
				} else {
					context.dispatch('getIntegralRuleDetail')
				}
			});
			context.commit('setIntegralSum', sum)
			context.commit('setMaxIntegralBank', maxIntegralBank)
			// if (data.data.data && data.data.data.length)
		},
		//获取积分规则列表
		async getIntegralRuleDetail(context) {
			await IntegralRuleDetail({ "object": {} })
				.then(res => {
					if (res.data.code !== "SUCCESS") throw Error(res.data.msg);
					context.commit('setIntegralRule', res.data.data)
				})
				.catch(e => {
					_this.$toast.fail('获取积分规则列表失败')
					console.error(e.message)
				})
		},
		//获取任务列表
		async getTaskQueryList(context) {
			await taskQueryList({
				object: {
					id: context.state.userInfo.id,
				},
			})
				.then((res) => {
					if (res.data.code !== "SUCCESS") throw Error(res.data.msg);
					let list = [
						{ type: "2", list: [], title: "新人任务" },
						{ type: "1", list: [], title: "日常任务" },
						// { type: "3", list: [] },
					]
					let url1 = [
						'',
						'/home/homePage',
						'/specialArea/newProductExpress',
						'/hotList',
						'/goodsList',
						'/home/video',
						'share',
					]
					let url2 = [
						'/userData/name',
						'/userData',
						'/home/homePage',
					]
					res.data.data.forEach((e) => {
						list.forEach((t) => {
							if (e.taskType === t.type) t.list.unshift(e);
						});
					});
					list.forEach(t => {
						t.show = true
						let index = 0
						t.list.forEach((e, i) => {

							if (e.taskType === '2') {
								if (e.times === e.upLimit) index++
								e.url = url2[i]
							}
							e.taskType === '1' && (e.url = url1[i])
							if (e.taskType === '1' && i === 2 || i === 3 || i === 4 || i === 5) e.countdownTask = true
						})
						if (t.type === '2' && index === t.list.length) t.show = false
					})
					console.log(list);
					context.commit('setTaskList', list)
				})
				.catch((e) => {
					_this.$toast.fail("获取任务列表失败");
					console.error(e.message);
				});
		},
		//完成任务
		async taskFinished(context, val) {
			if (!context.state.taskList.length)
				await context.dispatch("getTaskQueryList");
			if (val === undefined) {
				let list = []
				context.state.taskList.forEach((l) => {
					l.list.forEach(e => {
						list.push(e)
					})
				})

				let url = [
					'/userData/name',
					'/userData',
					'/home/homePage',
					'',
					'/home/homePage',
					'/specialArea/newProductExpress',
					'/hotList',
					'/goodsList',
					'/home/video',
					'',
				]
				let index = url.findIndex(e => {
					return e === _this.$route.path
				})
				if (index === -1 || !list[index].taskStatus || list[index].times >= list[index].upLimit) return
				val = list[index].id
			}


			return new Promise((resolve, reject) => {
				taskFinished({
					"object": {
						"referrerUserId": "", //被邀用户id
						// "taskId": '', //任务id
						"taskId": val, //任务id
						// "times": "1", //完成次数
						"userId": context.state.userInfo.id //用户id
					}
				}).then(async res => {
					if (res.data.code !== "SUCCESS") throw Error(res.data.msg);
					await context.dispatch("getTaskQueryList")
					resolve(res.data.data)
				}).catch(e => {
					reject(e.message)
				})
			})

		},

		// async getSignInDaysList(context) {
		// 	_this.$toast.loading({
		// 		duration: 0,
		// 		message: "加载中...",
		// 		forbidClick: true,
		// 	});
		// 	await checkInRuleDetails({
		// 		object: {},
		// 	}).then(async res => {
		// 		// throw Error(res.data.msg);
		// 		if (res.data.code !== "SUCCESS") throw Error(res.data.msg);
		// 		let bonusPoints = []
		// 		res.data.data.forEach((e) => {
		// 			if (e.doubling || e.extraIntegral) bonusPoints.push(e);
		// 		});

		// 		context.commit('setSignInDaysList', res.data.data)
		// 		context.commit('setBonusPoints', bonusPoints)
		// 	}).catch(e => {
		// 		_this.$toast.clear();
		// 		_this.$toast.fail('获取签到列表失败')
		// 		console.error(e.message);
		// 		throw Error(e.message)
		// 	})
		// 	// console.log(new Date().getDay());
		// },
		// async getCheckInRecord(context) {
		// 	await checkInRecord({
		// 		"object": {
		// 			"userId": context.state.userInfo.id
		// 		}
		// 	}).then(res => {
		// 		// throw Error(res.data.msg)
		// 		if (res.data.code !== "SUCCESS") throw Error(res.data.msg);
		// 		let num = 0
		// 		let signIn = false
		// 		let weekDay = new Date().getDay()
		// 		res.data.data.forEach(e => {
		// 			if (weekDay === e.weekDay) signIn = true
		// 			num++
		// 		});

		// 		context.commit('setSignInDaysNum', num)
		// 		context.commit('setIsSignIn', signIn)
		// 		_this.$toast.clear();
		// 	}).catch(e => {
		// 		_this.$toast.clear();
		// 		_this.$toast.fail('获取签到记录失败')
		// 		console.error(e.message);
		// 		throw Error(e.message)
		// 	})
		// },
		// async signIn(context) {
		// 	let that = context.state
		// 	if (!that.signInDaysList.length) {
		// 		await context.dispatch("getSignInDaysList").then(async (res) => {
		// 			await context.dispatch("signIn")
		// 		}).catch(e => { })
		// 		return
		// 	} else if (that.isSignIn === null) {
		// 		await context.dispatch("getCheckInRecord").then(async (res) => {
		// 			await context.dispatch("signIn")
		// 		}).catch(e => { })
		// 		return
		// 	}
		// 	if (that.isSignIn) {
		// 		_this.$toast.fail('今日已签到')
		// 		return
		// 	}
		// 	let integral = 0
		// 	let today = that.signInDaysList[that.signInDaysNum]

		// 	if (today.doubling) {
		// 		integral = today.integral * 2
		// 	} else if (today.extraIntegral) {
		// 		integral = today.extraIntegral
		// 	} else {
		// 		integral = today.integral
		// 	}

		// 	await insertCheckInRecord(
		// 		{
		// 			"object": {
		// 				"integral": integral,
		// 				"userId": that.userInfo.id
		// 			}
		// 		}
		// 	).then(async res => {
		// 		if (res.data.code !== "SUCCESS") throw Error(res.data.msg);
		// 		await context.dispatch("getSignInDaysList")
		// 		context.commit('setIsSignIn', true)
		// 		signInTips(integral)
		// 	}
		// 	).catch(e => {
		// 		_this.$toast.fail('签到失败')
		// 		console.error(e.message);
		// 	})

		// }

	}
}
