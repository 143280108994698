import View from './View'
const register = {
  path: '/register',
  component: View,
  children: [
    {
      path: '',
      name: 'register',
      component: () =>
        import( /* webpackChunkName: "Registration" */ '@/views/Login'),
      meta: {
        title: "注册",
        isShow: true
      },
    },
    {
      path: 'registration',
      name: 'registration',
      component: () =>
        import( /* webpackChunkName: "Registration" */ '@/views/Login/Registration'),
      meta: {
        name: "申请团长",
      }
    },
    {
      path: 'applicationStatus',
      name: 'application-status',
      component: () =>
        import( /* webpackChunkName: "ApplicationStatus" */ '@/views/Login/ApplicationStatus'),
      meta: {
        name: "申请进度",
      }
    },
    {
      path: 'capitalAccount',
      name: 'capital-account',
      component: () =>
        import( /* webpackChunkName: "CapitalAccount" */ '@/views/Login/CapitalAccount'),
      meta: {
        name: "开通资金账户",
      }
    },
  ]
}

export default register