
import View from './View'

const homePage = {
  path: '/',
  component: View,
  redirect: '/home',
  children: [
    {
      path: 'home',
      component: () =>
        import( /* webpackChunkName:'' */ '@/views/Home/'),
      redirect: '/home/homePage',
      children: [
        {
          path: 'homePage',
          name: 'home-page',
          component: () =>
            import( /* webpackChunkName:'HomePage' */ '@/views/Home/HomePage'),
          meta: {
            keepAlive: true
          },
        },
        // {
        //   path: 'homePage2',
        //   name: 'home-page2',
        //   component: () =>
        //     import( /* webpackChunkName:'HomePage' */ '@/views/Home/HomePage2'),
        //   meta: {
        //     keepAlive: true,
        //   },
        // },
        {
          path: 'video',
          name: 'video',
          component: () =>
            import( /* webpackChunkName:'Video' */ '@/views/Home/Video'),
          meta: {
            isShow: true,
            noFixed: true,
            transparent: true,
          }
        },
        {
          path: 'classification',
          name: 'classification',
          component: () =>
            import( /* webpackChunkName:'Classification' */ '@/views/Home/Classification'),
        },
        {
          path: 'shoppingCart',
          name: 'shopping-cart',
          component: () =>
            import( /* webpackChunkName:'ShoppingCart' */ '@/views/Home/ShoppingCart'),
        },
        {
          path: 'mine',
          name: 'mine',
          component: () =>
            import( /* webpackChunkName:'Mine' */ '@/views/Home/Mine'),
        },
      ]
    },

  ]
}

export default homePage