
import axios from 'axios'

// create an axios instance
const service = axios.create({
    baseURL: 'https://fileUploads.realbrand.net', // url = base url + request url 
    // baseURL: 'http://192.168.1.156:20003', // url = base url + request url
    // withCredentials: true, // send cookies when cross-domain requests
    timeout: 10000 // request timeout
})

service.interceptors.request.use(config => {

    return config
},
    err => {
        console.log(err)
        return Promise.reject(err)
    });

export async function imageUpload(data) {
    return await service({
        url: '/file/fileUpload',
        method: 'post',
        data
    })
}
