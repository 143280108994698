<template>
  <transition name="van-fade">
    <div
      v-show="show"
      class="mask"
      ref="mask"
      @click.self="show = false"
      id="share_popup"
    >
      <div class="share_popup" v-if="info">
        <div ref="share" class="share_content">
          <div class="title">
            <img src="../../assets/images/logo.png" /> 正品汇商城
          </div>
          <img :src="info.filePath" width="100%" class="goods_img" />
          <div class="details">
            <div class="left">
              <p class="status">
                {{
                  info.goodsStatus
                    ? info.goodsStatus === 1
                      ? "尝鲜价"
                      : info.goodsStatus === 2
                      ? "秒杀价"
                      : info.goodsStatus === 4
                      ? "特惠价"
                      : ""
                    : ""
                }}
              </p>
              <div class="price">
                <span>¥</span>
                <b>{{
                  info.goodsStatus
                    ? info.goodsStatus === 1
                      ? info.entity.price.toFixed(2)
                      : info.goodsStatus === 2
                      ? info.seckillPrice.retailSeckillPrice.toFixed(2)
                      : info.goodsStatus === 4
                      ? info.preferentialRetailPrice.toFixed(2)
                      : info.retailPrice
                    : info.retailPrice
                }}</b>
                <span>/{{ info.prickle }}</span>
                <span class="old_price" v-if="info.goodsStatus"
                  >¥{{ info.retailPrice }}</span
                >
              </div>
              <div class="name">{{ info.commodityName }}</div>
            </div>
            <div class="code">
              <div class="img_box">
                <img :src="QRImgUrl" class="QR_img" width="100%" />
                <img src="../../assets/images/logo.png" alt="" class="logo" />
              </div>
              <p>长按识别查看商品</p>
            </div>
          </div>
        </div>
        <div class="share_img">
          <van-loading v-if="!imgSrc" color="#0094ff" />
          <img :src="imgSrc" width="100%" v-else />
        </div>
      </div>
      <p class="tips">长按保存至相册</p>
      <svg-icon icon-class="close_icon" @click="show = false" class="close" />
    </div>
  </transition>
</template>

<script>
import QRCode from "qrcode";
import html2canvas from "html2canvas";
import _this from "@/main.js";

export default {
  name: "share-popup",
  data() {
    return {
      show: false,
      info: null, //0无特殊,1新人尝鲜,2限时秒杀,3积分商品,4特惠价
      QRImgUrl: "",
      imgSrc: "",
    };
  },
  watch: {
    show: {
      handler(val) {
        if (val) {
          document.body.style.overflow = "hidden";
          this.$refs.mask.addEventListener(
            "touchmove",
            this.preventDefault,
            true
          );
        } else {
          document.body.style.overflow = "";
          this.$refs.mask.removeEventListener(
            "touchmove",
            this.preventDefault,
            true
          );
        }
      },
    },
    info: {
      handler(val) {
        val.filePath = val.filePath.split(",")[0].endsWith(".mp4")
          ? val.filePath.split(",")[1]
          : val.filePath.split(",")[0];
      },
    },
  },

  mounted() {
    this.getQRcode();
  },
  methods: {
    preventDefault(event) {
      event.preventDefault();
    },
    getQRcode() {
      let promoterUuid = _this.$store.state.user.promoter;
      let opts = {
        errorCorrectionLevel: "H", //容错级别
        type: "image/png", //生成的二维码类型
        margin: 0,
        color: {
          dark: "#000", //前景色
        },
      };
      //this.QRlink 生成的二维码地址url
      let url = `${window.location.origin}/${window.location.hash}${
        promoterUuid ? `&promoterUuid=${promoterUuid.userId}` : ""
      }`;
      console.log(url);

      QRCode.toDataURL(url, opts, (err, url) => {
        if (err) throw err;
        this.QRImgUrl = url;
      });

      setTimeout(() => {
        this.convert();
      }, 200);
    },
    convert() {
      const options = {
        // width: 500,
        // height: 500,
        useCORS: true,
        logging: true,
        backgroundColor: "#fff",
        scale: 2,
        // dpi: 600,
      };

      html2canvas(this.$refs.share, options)
        .then((canvas) => {
          // box.appendChild(canvas);

          this.imgSrc = canvas
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
          this.isLoading = false;
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.mask {
  position: fixed;
  top: 0;
  width: 100%;
  max-width: 500px;
  bottom: 0;
  z-index: 100000;
  background-color: rgba(0, 0, 0, 0.5);
  font-size: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.share_popup {
  color: #333;
  position: relative;
  width: 80%;
  border-radius: 20px;
  .share_content {
    background: #fff;
    border-radius: 20px;
    padding: 0 16px 16px;
    .title {
      line-height: 18px;
      padding: 12px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      img {
        width: 18px;
        margin-right: 6px;
      }
    }
    .goods_img {
      border-radius: 10px;
      display: block;
      margin-bottom: 16px;
    }
    .details {
      display: flex;
      .left {
        width: 62%;
        flex-shrink: 0;
        .status {
          font-size: 14px;
          color: #f91c12;
        }
        .price {
          color: #f91c12;
          margin-bottom: 4px;
          b {
            font-size: 24px;
          }
          span {
            font-size: 12px;
          }
          .old_price {
            color: #999999;
            font-size: 16px;
            margin-left: 6px;
            text-decoration: line-through;
          }
        }
        .name {
          overflow: hidden;
          text-overflow: ellipsis;
          font-weight: bold;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
      }
      .code {
        width: 38%;
        flex-shrink: 0;
        .img_box {
          position: relative;
          padding: 0 6px;
          .logo {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 18px;
            background: #fff;
            padding: 2px;
            margin-top: -9px;
            margin-left: -9px;
          }
        }
        p {
          font-size: 12px;
          color: #999;
          text-align: center;
        }
      }
    }
  }
  .share_img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      border-radius: 20px;
    }
  }
}
.tips {
  color: #fff;
  margin-top: 24px;
}
.close {
  font-size: 32px;
  margin-top: 32px;
}
</style>
