import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import functionButton from "./components/FunctionButton";
Vue.component('functionButton', functionButton);
// Vue.config.productionTip = false
import * as API from './api'
Vue.prototype.$API = API

import '@/icons'

import vant from 'vant';
import { Lazyload, Toast } from 'vant';
Vue.use(Lazyload, {
  preload: 1.3,
  loading: require('@/assets/images/loading.gif'),
  error: require('@/assets/images/error.png')
});
Vue.prototype.Toast = Toast;

import getBankcardinfo from 'bankcardinfo'
Vue.prototype.$getBankcardinfo = getBankcardinfo

import 'vant/lib/index.css';

Vue.config.ignoredElements = ['wx-open-launch-app', 'wx-open-launch-weapp']

import BaiduMap from 'vue-baidu-map'

Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: 'NxHnp2nBdXGkeqqlAgmCvDSwcQ214Lcj'
})


// import VueAMap from 'vue-amap'
// Vue.use(VueAMap);
// // 初始化vue-amap
// VueAMap.initAMapApiLoader({
//   key: 'b09f7a336688ccd720e716fb766bdcc2',// 高德的key
//   // 插件集合
//   plugin: [
//     'AMap.CircleEditor',// 圆形编辑器插件
//     "AMap.Geolocation", // 定位控件，用来获取和展示用户主机所在的经纬度位置
//     "AMap.Geocoder", // 地理编码与逆地理编码服务，用于地址描述与坐标间的相互转换
//     "AMap.Autocomplete",
//     "AMap.PlaceSearch",
//     "AMap.CitySearch",
//   ],
//   // 高德 sdk 版本，默认为 1.4.4
//   v: '1.4.4'
// });
// //高德的安全密钥
// window._AMapSecurityConfig = {
//   securityJsCode: 'b6c404e1e93f7febfe38a2d4c5bc546f',
// }


Vue.use(vant);

const vue = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
export default vue


import VueTouch from './utils/vue-touch'


//屏幕滑动事件 未使用
Vue.directive("tap", {
  bind: function (el, binding) {
    new VueTouch(el, binding, "tap");
  }
});
Vue.directive("swipe", {
  bind: function (el, binding) {
    new VueTouch(el, binding, "swipe");
  }
});
Vue.directive("swipeleft", {
  bind: function (el, binding) {
    new VueTouch(el, binding, "swipeleft");
  }
});
Vue.directive("swiperight", {
  bind: function (el, binding) {
    new VueTouch(el, binding, "swiperight");
  }
});
Vue.directive("swipedown", {
  bind: function (el, binding) {
    new VueTouch(el, binding, "swipedown");
  }
});
Vue.directive("swipeup", {
  bind: function (el, binding) {
    new VueTouch(el, binding, "swipeup");
  }
});
Vue.directive("longtap", {
  bind: function (el, binding) {
    new VueTouch(el, binding, "longtap");
  }
});