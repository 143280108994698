var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[_c('van-nav-bar',{directives:[{name:"show",rawName:"v-show",value:(_vm.$route.meta.isShow),expression:"$route.meta.isShow"}],class:[
      _vm.$route.name === 'wallet' ? 'wallet_bar' : '',
      _vm.$route.meta.transparent ? 'transparent_bar' : '' ],style:([
      { '--color': _vm.$route.meta.color ? _vm.$route.meta.color : '#333' },
      { background: _vm.$route.meta.background ? _vm.$route.meta.background : '' } ]),attrs:{"title":_vm.$route.meta.title,"fixed":!_vm.$route.meta.noFixed,"z-index":"999"},on:{"click-left":_vm.goBack,"click-right":function($event){return _vm.$router.replace('/home/shoppingCart')}},scopedSlots:_vm._u([{key:"left",fn:function(){return [(_vm.back)?_c('van-icon',{attrs:{"name":"arrow-left"}}):_c('van-icon',{attrs:{"name":"wap-home-o"}})]},proxy:true},{key:"right",fn:function(){return [(
          _vm.$route.name === 'early-adopters' ||
          _vm.$route.name === 'hot-list' ||
          _vm.$route.name === 'great-value-special' ||
          _vm.$route.name === 'new-product-express'
        )?_c('van-icon',{attrs:{"name":"shopping-cart-o","color":"#fff","size":"20px","badge":_vm.quantity > 99 ? '99+' : _vm.quantity}}):_vm._e()]},proxy:true}])}),(_vm.taskShow)?_c('div',{staticClass:"countdown",style:([
      { top: _vm.$route.name === 'goods-list' ? '1.25rem' : '' },
      { position: _vm.$route.name === 'new-product-express' ? 'absolute' : '' } ])},[_vm._v(" "+_vm._s(_vm.currentTask.time ? _vm.currentTask.time < 0 ? "完成任务接口报错，刷新页面重试" : ("浏览" + (_vm.currentTask.time) + "秒即可领取积分") : "已完成浏览任务，可领取积分")+" ")]):_vm._e(),_c('keep-alive',[(_vm.$route.meta.keepAlive)?_c('router-view',{key:_vm.key}):_vm._e()],1),(!_vm.$route.meta.keepAlive)?_c('router-view',{key:_vm.key}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }