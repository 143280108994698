
const View = {
  name: "page-view",
  render(h) {

    return h('router-view')

  }
}
export default View
