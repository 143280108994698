import axios from 'axios'
import Cookies from "js-cookie";
// import store from '@/store'

// create an axios instance
const service = axios.create({
    baseURL: 'https://personal.origmall.cn', // url = base url + request url 
    // baseURL: process.env.VUE_APP_USER_API, // url = base url + request url 
    //20050
    // timeout: 30000 // request timeout
    timeout: 30000 // request timeout
})

service.interceptors.request.use(config => {
    // if (config.url === '/consumer-user-server/Integral/AdjustmentIntegral') {
    //     config.headers['OPERATOR_USER_ID'] = store.state.user.userInfo.id
    // }.
    config.headers['Region'] = "QG"

    if (config.url === '/consumer-shopping-server/Order/finishOrderDealByDealNumber')
        // config.headers['attach'] = "CS" 
        config.headers['attach'] = "QG"
    // config.headers['Region'] = "CS"

    // if (config.url === '/consumer-user-server/consumerUserFunds/cusApplications' || config.url === '/consumer-user-server/consumerUserFunds/sendSmsCodesByMobile' || config.url === '/consumer-user-server/consumerUserFunds/queryApplications' || config.url === '/consumer-user-server/consumerUserFunds/selectSettleAcctList' || config.url === '/consumer-user-server/consumerUserFunds/queryCusApplicationsInfo' || config.url === '/consumer-user-server/User/addPromoter' || config.url === '/consumer-user-server/User/queryPromoterById' || config.url === '/consumer-user-server/consumerUserFunds/queryElectronicAcct') config.headers['Region'] = "QG"


    const token = Cookies.get("token_personal");
    token && (config.headers.common['Authorization'] = `Bearer ${token}`)

    return config
},
    err => {
        console.log(err)
        return Promise.reject(err)
    });

export default service
