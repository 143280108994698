import request from '@/utils/request' //20003


//店铺详情
export async function storeHomePage(data) {
    return await request({
        url: '/business-server/HomePage/StoreHomePage',
        method: 'post',
        data
    })
}
//店铺类目
export async function getStoreCategory(data) {
    return await request({
        url: '/business-server/Classify/queryStoreIdCategoriesCatalog',
        method: 'post',
        data
    })
}