import request from '@/utils/request'

export async function BrandDetails(data) {
  return await request({
    url: '/business-server/HomePage/brandInfo',
    method: 'post',
    data
  })
}
//全部品牌列表
export async function BrandInitialsList(data) {
  return await request({
    url: '/business-server/HomePage/brandInitialsList',
    method: 'post',
    data
  })
}
//所有品牌 //查询品牌列表
export async function AllBrand(data) {
  return await request({
    url: '/business-server/HomePage/findBrandInfoList',
    method: 'post',
    data
  })
}
