import View from './View'
const pointsMall = {
  path: '/pointsMall',
  component: View,
  children: [
    {
      path: '',
      name: 'points-mall',
      component: () =>
        import( /* webpackChunkName:'PointsMall' */ '@/views/PointsMall'),
    },
    {
      path: 'memberPoints',
      name: 'member-points',
      component: () =>
        import( /* webpackChunkName:'MemberPoints' */ '@/views/PointsMall/MemberPoints'),
      meta: {
        title: "会员积分",
        isShow: true,
        color: "#fff",
        background: "#FFC016"
      },
    },
    {
      path: 'integralGoods',
      name: 'integral-goods',
      component: () =>
        import(/* webpackChunkName:'GoodsDetails' */  '@/views/Goods'),
    },
    {
      path: 'integralOrder',
      name: 'integral-order',
      component: () =>
        import(/* webpackChunkName:'MyOrder' */  '@/views/MyOrder'),
    }
  ]
}

export default pointsMall