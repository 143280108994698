import request from '@/utils/request'
import userRequest from '@/utils/userRequest'
//首页商品列表
export async function CommList(data) {
    return await request({
        url: '/business-server/HomePage/CommList',
        method: 'post',
        data
    })
}
//首页单品编码商品列表
export async function CommodityCodeList(data) {
    return await request({
        url: '/business-server/HomePage/CommodityCodeList',
        method: 'post',
        data
    })
}

//首页品牌列表
export async function QueryBrandList(data) {
    return await request({
        url: '/business-server/HomePage/queryBrandList',
        method: 'post',
        data
    })
}

//首页店铺列表
export async function storeList(data) {
    return await request({
        url: '/business-server/HomePage/StoreList',
        method: 'post',
        data
    })
}
//企业首页
export async function enterprise(data) {
    return await request({
        url: '/business-server/HomePage/EnterpriseHomePage',
        method: 'post',
        data
    })
}

//首页轮播图
export async function getBanner(data) {
    return await request({
        url: '/business-server/HomePage/slideshow',
        method: 'post',
        data
    })
}
//首页活动
export async function getActivityList(data) {
    return await request({
        url: '/business-server/Activity/homeActivityList',
        method: 'post',
        data
    })
}
//商品活动页面
export async function homeActivityComm(data) {
    return await request({
        url: '/business-server/Activity/homeActivityComm',
        method: 'post',
        data
    })
}
//活动首页
export async function homeActivity(data) {
    return await request({
        url: '/business-server/Activity/homeActivity',
        method: 'post',
        data
    })
}

//全部分类接口
export async function getAllClass(data) {
    return await request({
        url: '/business-server/Classify/queryCategoriesCatalogApi',
        method: 'post',
        data
    })
}

//溯源单品查详情
export async function queryBarcodeByCode(data) {
    return await request({
        url: '/business-server/Traceability/queryBarcodeByCode',
        method: 'post',
        data
    })
}

//视频列表
export async function queryVideoList(data) {
    return await request({
        url: '/business-server/Video/queryVideoList',
        method: 'post',
        data
    })
}

//尝鲜活动列表
export async function queryActivityList(data) {
    return await request({
        url: '/business-server/TastingActivity/queryActivityList',
        method: 'post',
        data
    })
}

//用户是否完成订单
export async function authorityActivity(data) {
    return await request({
        url: '/business-server/TastingActivity/authorityActivity',
        method: 'post',
        data
    })
}

//限时秒杀场次列表
export async function querySecKillInfoListByNow(data) {
    return await request({
        url: '/business-server/HomeSecKill/querySecKillInfoListByNow',
        method: 'post',
        data
    })
}

//秒杀商品列表
export async function querySecKillCommodityList(data) {
    return await request({
        url: '/business-server/HomeSecKill/querySecKillCommodityList',
        method: 'post',
        data
    })
}

//直播商品列表
export async function liveBroadcastList(data) {
    return await userRequest({
        url: '/consumer-shopping-server/LiveBroadcast/LiveBroadcastInfo',
        method: 'post',
        data
    })
}

