import View from './View'
const promotion = {
  path: '/promotion',
  component: View,
  children: [
    {
      path: '',
      name: 'promotion',
      meta: {
        title: "分佣推广",
        isShow: true,
        color: '#fff',
        background: "linear-gradient(180deg, #8EBEFE 0%, #5da5ff 100%)"
      },
      component: () =>
        import( /* webpackChunkName:'Promotion' */ '@/views/Promotion'),
    },
    {
      path: 'promotionRegistration',
      name: 'promotion-registration',
      meta: {
        title: "开通资金账户",
        isShow: true,
        color: '#fff',
        background: "#1A8AFF"
      },
      component: () =>
        import( /* webpackChunkName:'Promotion' */ '@/views/Promotion/PromotionRegistration'),
    },
    {
      path: 'successfulCertification',
      name: 'successful-certification',
      meta: {
        title: "认证成功",
        isShow: true,
      },
      component: () =>
        import(/* webpackChunkName:'SuccessfulCertification' */  '@/views/Promotion/SuccessfulCertification'),
    },
    {
      path: 'promotionOrder',
      name: 'promotion-order',
      meta: {
        title: "我的推广订单",
        isShow: true,
        background: "#F5F6FA"
      },
      component: () =>
        import(/* webpackChunkName:'PromotionOrder' */  '@/views/Promotion/PromotionOrder'),
    },
    {
      path: 'promotionOrder',
      name: 'promotion-order',
      meta: {
        title: "我的推广订单",
        isShow: true,
        background: "#F5F6FA"
      },
      component: () =>
        import(/* webpackChunkName:'PromotionOrder' */  '@/views/Promotion/PromotionOrder'),
    },
    {
      path: 'nounInterpretation',
      name: 'noun-interpretation',
      meta: {
        title: "名词解释",
        isShow: true,
        background: "#F5F6FA"
      },
      component: () =>
        import(/* webpackChunkName:'NounInterpretation' */  '@/views/Promotion/NounInterpretation'),
    },
    {
      path: 'commissionRule',
      name: 'commission-rule',
      meta: {
        title: "推广员等级和佣金计算规则",
        isShow: true,
        background: "#F5F6FA"
      },
      component: () =>
        import(/* webpackChunkName:'CommissionRule' */  '@/views/Promotion/CommissionRule'),
    },
    {
      path: 'instructions',
      name: 'instructions',
      meta: {
        title: "正品汇分佣推广",
        isShow: true,
      },
      component: () =>
        import(/* webpackChunkName:'Instructions' */  '@/views/Promotion/Instructions'),
    },
  ]
}

export default promotion